import React from 'react';
import '../Styles/about.css';
import fotoPortfolio from '../Immagini/fotoPortfolio.jpg';

function About() {
  return (
    <div className='container'>
      <h1 className='About'>About</h1>
      <p className='pres'>
        Hi, my name's Marco, I'm 13 years old and I love programming. I started programming in March 2023,
        without stopping since then.
      </p>
      <img src={fotoPortfolio} alt="" />
    </div>
  );
}

export default About;
