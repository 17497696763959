import React from 'react';
import '../Styles/home.css';
import { useNavigate } from 'react-router-dom';

function Home() {
  const navigate = useNavigate()
  return (
    
<div class="contenitore">
  <h1 class='nome'>PORTFOLIO</h1>
  <div class="menu">
    <div class="item" onClick={()=>navigate("/home")}>/home</div>
    <div class="item"  onClick={()=>navigate("/about")}>/about</div>
    <div class="item"  onClick={()=>navigate("/contatti")}>/contatti</div>
  </div>
  
</div>

 );
}

export default Home;
