import '../Styles/contatti.css'

function contatti() {
    return(
<div>
    <h1 className="titolo">Contatti</h1>
    <p className='conME'>Se ti va di entrare a contatto con me, <br/> ecco il metodo</p>
    <p className='metodo'>Possiamo entrare in contatto tramite <br/> <a href="#" className='instagram'>Instagram</a> oppure <a href="#" className='telegram'>Telegram</a> </p>
    <p className='email'>Se c'è dell'altro puoi scrivermi da e-mail: <br /> <a href="#">admin@gmail.com</a></p>
</div>

    )
}

export default contatti;