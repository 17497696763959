import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Componenti/home.jsx';
import About from './Componenti/about.jsx';
import Contatti from './Componenti/contatti.jsx';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contatti" element={<Contatti />} />
      </Routes>
    </BrowserRouter>
  );
}


export default App;
